<template>
  <div class="recovery-second-step">
    <p class="recovery-second-step__title">{{ $t('code-to-email', {email: form.email}) }}</p>
    <ui-input
        :label="$t('enter-confirmation-code')"
        v-model="authCode"
        :error="$v.authCode.$error || catchError"
        :error-text='getCodeErrorText'
    />
    <div class="recovery-second-step__line"></div>
    <div class="recovery-second-step__actions">
      <ui-button @click="sendCodeAgain" :disabled="buttonDisabled" color="outline">
        {{ $t('send-code-again') }}
      </ui-button>
      <UiButton :disabled="!authCode || $v.$anyError" @click="submit">
        {{ $t('continue') }}
      </UiButton>
    </div>
    <ui-alert v-model="openErrorModal">
      <h5 class="ui-alert__title">
        {{ alertErrorText }}
      </h5>
      <UiButton color="error" @click="openErrorModal = false">
        {{ $t('okay') }}
      </UiButton>
    </ui-alert>
  </div>
</template>


<script>
import {minLength, required} from "vuelidate/lib/validators";
import {mapActions} from "vuex";
export default {
  name: "PasswordRecoverySecondStep",
  inject: ['form'],
  components: {
    UiInput: () => import("@/components/ui/UiInput"),
    UiButton: () => import("@/components/ui/UiButton"),
    UiAlert: () => import("@/components/ui/UiAlert"),
  },
  validations() {
    return {
      authCode: {
        required,
        minlength: minLength(5)
      },
    }
  },
  data() {
    return {
      authCode: '',
      catchError: false,
      openErrorModal: false,
      buttonDisabled: false,
      alertErrorText: ''
    }
  },
  computed: {
    getCodeErrorText() {
      if (!this.$v.authCode.minLength && !this.catchError) {
        return this.$t('code-must-be-5')
      }

      return this.$t('error-code')
    },
  },
  mounted() {
    document.addEventListener('keydown', this.enter)
  },

  // destroyed() {
  //   document.removeEventListener('keydown',  this.enter, false);
  // },
  methods: {
    ...mapActions(['reset']),
    sendCodeAgain() {
      this.buttonDisabled = true
      this.reset({
        email: this.form.email
      }).then(() => {
        this.buttonDisabled = false
      }).catch((err) => {
        this.buttonDisabled = false
        this.openErrorModal = true
        this.alertErrorText = err.response.data.message;
      })
    },
    submit() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.reset({
          email: this.form.email,
          authCode: this.authCode
        }).then(() => {
          this.form.authCode = this.authCode
          this.$emit('continue')
        }).catch(() => {
          this.catchError = true
        })
      }
    },
    enter(e) {
      if(e.keyCode === 13) {
        this.submit()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.recovery-second-step {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 116.7%;
    color: #343432;
    margin-bottom: 30px;
    text-align: center;
  }

  &__line {
    border-bottom: 1px solid #FFFFFF;
    margin: 20px 0;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
